@import '@angular/material/theming';
@include mat-core();

$my-app-primary: mat-palette($mat-pink, 900);
$my-app-accent:  mat-palette($mat-grey, 400);
$my-app-warn:    mat-palette($mat-pink, 'A400');

$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);

@include angular-material-theme($my-app-theme);


@mixin mix-app-theme($app-theme) {
    $primary: map-get($app-theme, primary);
    $accent: map-get($app-theme, accent);
  
    .mat-button-toggle {
      background-color: mat-color($primary, default-contrast);
      color: #000000;
    }
  
    .mat-button-toggle-checked {
      background-color: #FCE4EC;
      color: mat-color($primary);
    }
  }
  
  // Include the mixin
  @include mix-app-theme($my-app-theme);