/* You can add global styles to this file, and also import other style files */
@import 'theme.scss';
@import 'material-icons/iconfont/material-icons.scss';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.accent-back {
    background-color: #e0e0e0;
}

.accent-fore {
    color: #e0e0e0;
}

.bold {
    font-weight: bold;
}

.dark-grey {
    color: #757575;
}

.flex-spacer {
    flex: 1 1 auto;
}

.footer-pad {
    padding-bottom: 20px;
}

.grecaptcha-badge {
    visibility: hidden;
        // top: 0px !important;
}
.header {
    position: fixed; 
    top: 0; 
    overflow: hidden; 
    z-index:10;
}
.mat-checkbox-label {
    white-space: initial; // forces checkbox labels to wrap
}

.primary-back {
    background-color: #880e4f;
}

.primary-fore {
    color: #880e4f;
}
.toolbar {
    height: 7.5vh;
    padding-left: 5%;
    padding-right: 16px;
}
.warn-back {
    background-color: #f50057;
}

.warn-fore {
    color: #f50057 !important;
}
.wrap-text {
    white-space: pre-line;   
}

.mat-form-field.mat-focused {
    .mat-form-field-label {
        color: grey;
    }
    .mat-form-field-ripple {
        background-color: grey;
    }
    .mat-form-field-required-marker {
        color: grey;
    }
    // .mat-input-element {
    //     color: grey;
    // }
}